<template>
  <div>
    <mia-navback @back="back" :isShowSave="!isView" @submit="submitForm" />
    <div class="header">
      <el-form :inline="true">
        <el-form-item label="台账编号:">
          {{ learnLedger.no }}
        </el-form-item>
        <el-form-item v-if="learnLedger.updateDate" label="修改时间:">
          {{ learnLedger.updateDate }}
        </el-form-item>
        <el-form-item v-if="learnLedger.updateUserName" label="最后修改人:">
          {{ learnLedger.updateUserName }}
        </el-form-item>
      </el-form>
    </div>
    <div class="content">
      <table>
        <caption style="font-size: 24px; font-weight: 600; margin-bottom: 20px">
          农药经营人员岗位与培训学习记录
        </caption>
        <tbody>
          <tr>
            <td>时间</td>
            <td style="text-align: left">
              <el-date-picker
                v-model="learnLedger.trainDate"
                :disabled="isView"
                type="date"
                placeholder="选择日期"
              >
              </el-date-picker>
            </td>
          </tr>
          <tr>
            <td>地点</td>
            <td style="text-align: left">
              <el-input
                style="width: 100%"
                v-model="learnLedger.trainAddress"
                :disabled="isView"
                type="text"
              ></el-input>
            </td>
          </tr>
          <tr>
            <td>召集人</td>
            <td style="text-align: left">
              <el-input
                style="width: 100%"
                v-model="learnLedger.callName"
                :disabled="isView"
                type="text"
              ></el-input>
            </td>
          </tr>
          <tr>
            <td>参加人员</td>
            <td style="text-align: left">
              <div>（参加学习人员实名制签名）：</div>
              <el-input
                style="width: 100%"
                v-model="learnLedger.attendName"
                rows="3"
                :disabled="isView"
                type="textarea"
              ></el-input>
            </td>
          </tr>
          <tr>
            <td colspan="8" style="font-size: 20px; font-weight: 600">
              学习内容
            </td>
          </tr>
          <tr>
            <td colspan="8">
              <el-input
                style="width: 100%"
                v-model="learnLedger.trainContent"
                rows="10"
                :disabled="isView"
                type="textarea"
              ></el-input>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <Sign
      :visible="signatureVisible"
      @finsih="signFisish"
      @close="signatureVisible = false"
    ></Sign>
  </div>
</template>
<script>
import Sign from "@/components/Sign";
import { uploadFile } from "@/api/common";
import { imgUrl } from "../../../../settting";
import {
  getLearnLedgerCode,
  getLearnLedgerDetails,
  addLearnLedger,
  editLearnLedger,
} from "@/api/learnLedger";
import moment from "moment";
export default {
  components: { Sign },
  data() {
    return {
      learnLedger: {},
      checkPlace: [],
      isView: false,
      signatureVisible: false,
    };
  },
  methods: {
    init(id, view) {
      if (id) {
        getLearnLedgerDetails(id).then((res) => {
          if (res.code === 1) {
            this.learnLedger = res.data;
            this.isView = view;
          }
        });
      } else {
        getLearnLedgerCode().then((res) => {
          if (res.code == 1) {
            this.learnLedger = {
              ...this.learnLedger,
              no: res.data,
              trainDate: moment().format("YYYY-MM-DD"),
            };
          }
        });
      }
    },
    getImg(url) {
      return imgUrl + url;
    },
    valChange(evt, name, val) {
      if (!evt) {
        this.safeItems = this.safeItems.map((v) => {
          if (name === v.name) {
            v.value = val;
          }
          return v;
        });
      } else {
        if (val === "1") {
          this.safeItems = this.safeItems.map((v) => {
            if (name === v.name) {
              v.method = "0";
            }
            return v;
          });
        }
      }
    },
    val1Change(evt, name, val) {
      if (!evt) {
        this.safeItems = this.safeItems.map((v) => {
          if (name === v.name) {
            v.method = val;
          }
          return v;
        });
      }
    },
    back() {
      this.$emit("back");
    },
    checkPlaceChange(val) {
      this.checkPlace = val;
    },
    submitForm() {
      if (!this.learnLedger.trainAddress) {
        return this.$message.error("请填写地点");
      }
      if (!this.learnLedger.callName) {
        return this.$message.error("请填写召集人");
      }
      if (!this.learnLedger.attendName) {
        return this.$message.error("请填写参加人员");
      }
      if (!this.learnLedger.trainContent) {
        return this.$message.error("请填写学习内容");
      }
      if (this.learnLedger.id) {
        editLearnLedger(this.learnLedger).then((res) => {
          if (res.code === 1) {
            this.$message.success("操作成功");
            this.back();
          } else {
            this.$message.error(res.msg);
          }
        });
      } else {
        addLearnLedger(this.learnLedger).then((res) => {
          if (res.code === 1) {
            this.$message.success("操作成功");
            this.back();
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
    openSign() {
      this.signatureVisible = true;
    },
    signFisish(val) {
      const imgs = this.base64ImgtoFile(val);
      const formData = new FormData();
      formData.append("file", imgs);
      uploadFile(formData).then((res) => {
        if (res.code === 1) {
          this.learnLedger = { ...this.learnLedger, sign: res.url };
        }
      });
      this.signatureVisible = false;
    },
    base64ImgtoFile(dataurl, filename = "file") {
      let arr = dataurl.split(",");
      let mime = arr[0].match(/:(.*?);/)[1];
      let suffix = mime.split("/")[1];
      let bstr = atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], `${filename}.${suffix}`, {
        type: mime,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.header {
  width: 80%;
  margin: 0 auto;
  padding: 20px 50px;
  /deep/ .el-form-item {
    width: 32%;
    &:nth-child(1) {
      text-align: left;
    }
    &:nth-child(2) {
      text-align: center;
    }
    &:nth-child(3) {
      text-align: right;
    }
  }
}
/deep/ .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
  background-color: #409eff;
  color: #409eff;
}
.content {
  text-align: center;
  width: 80%;
  margin: 0 auto;
}
table {
  width: 100%;
}
.inline-table {
  border: 0px;
}
table,
td {
  border-collapse: collapse;
  border: 1px solid #999;
}
td {
  padding: 15px;
  font-size: 14px;
}
.sign {
  width: 100px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  cursor: pointer;
}
</style>
